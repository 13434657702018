import { useState, useEffect, useRef } from "react";
import { Header } from "../../shared/Header";
import { useNavigate } from "react-router-dom";
import Popup from "../../shared/PopUp";
import "./createAccount.scss";
import { useLoading } from "../../auth/loader/loading.context";
import { createAccountWithDetails } from "../../API/api";
import {
  isEmptyInput,
  isEmptyObject,
  isValidPassword,
  isValidUsername,
  PopUpType,
} from "../../shared/Validation/inputValidation";

export function CreateAccount() {
  const [isPassword, setPassword] = useState(true);
  const [isConfirmPassword, setConfirmPassword] = useState(true);
  const { loading, setLoading } = useLoading();
  const navigate = useNavigate();

  const popupRef = useRef<{ updateErrorMessage: (apiError: string) => void }>(
    null
  );

  const [showError, setShowError] = useState<string>("");

  const userDetails = JSON.parse(sessionStorage.user);

  const createAccount = async () => {
    setLoading(true);
    const data: any = await createAccountWithDetails(
      inputFields.username,
      inputFields.password
    );
    if (data.data.IsSuccess === true) {
      let redirectURL = "https://app.mozzazcare.com/";
      if (data.data.Data.NeedsRedirect && data.data.Data.NeedsRedirect === true){
        if(data.data.Data.AccountStatus && data.data.Data.AccountStatus === 6){
          redirectURL = data.data.Data.RedirectURL ? data.data.Data.RedirectURL : "https://corelifeapp.mozzazcare.com/";
        }else{
          redirectURL = "https://corelifeapp.mozzazcare.com/";
        }
      }
      else if (userDetails.ParentOrgId === "121" || userDetails.OrgId === "121") {
        redirectURL = "https://app.dulalytics.com/";
      } else if (
        userDetails.ParentOrgId === "118" ||
        userDetails.OrgId === "118"
      ) {
        redirectURL = "https://corelifeapp.mozzazcare.com/";
      } else if (
        userDetails.ParentOrgId === "120" || userDetails.OrgId === "174"
      ) {
        redirectURL = "https://healthsparkapp.mozzazcare.com/";
      } else {
        redirectURL = "https://app.mozzazcare.com/";
      }
      window.location.href = redirectURL;

      /*
      "https://app.mozzazcare.com/"
        https://corelife-setup-dev.mozzazcare.com/
        else if (process.env.REACT_APP_ENVIRONMENT === "dev") {
        redirectURL = "https://mozzaz-lt-ui-dev.azurewebsites.net/";
      } else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
        redirectURL = "https://mozzaz-lt-ui-qa.azurewebsites.net/";
      } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
        redirectURL = "https://mozzaz-lt-ui-stage.azurewebsites.net/";
      }
       */
    } else {
      if (
        data.data.Data.Error_Type === "ACCESS_ERROR" &&
        data.data.Data.Error === "Invalid_Authentication_Token"
      ) {
        navigate("/verify");
      } else {
        setShowError(
          data.data.Data.Error_Message
            ? data.data.Data.Error_Message
            : data.data.Data.Error
        );
      }
    }
    setLoading(false);
  };

  window.onloadedmetadata = () => {
    setLoading(false);
  };

  const [inputFields, setInputFields] = useState({
    username: "",
    password: "",
    confirm: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    confirm: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues: any) => {
    let errors = { username: "", password: "", confirm: "" };
    if (isEmptyInput(inputValues.username)) {
      errors.username = "Please enter Username";
    } else if (!isValidUsername(inputValues.username)) {
      errors.username = "Username does not meet requirements";
    }
    if (isEmptyInput(inputValues.password)) {
      errors.password = "Please enter Password";
    } else if (!isValidPassword(inputValues.password, inputValues.username)) {
      errors.password = "Password does not meet requirements";
    }
    if (isEmptyInput(inputValues.confirm)) {
      errors.confirm = "Please enter Confirm Password";
    } else if (inputValues.password !== inputValues.confirm) {
      errors.confirm = "Passwords do not match";
    }
    return errors;
  };

  const handleChange = (e: any) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  useEffect(() => {
    if (isEmptyObject(errors) && submitting) {
      createAccount();
    }
  }, [errors]);

  return (
    <div>
      <Header></Header>
      <form onSubmit={handleSubmit}>
        <div className="flex w-full h-full justify-center items-center">
          <div className="p-6 w-full h-full max-w-sm md:h-full">
            <header className="mt-16 text-3xl font-semibold text-verydarkgrey">
              Create Account
            </header>
            <p className="mb-5 flex w-full items-center info-style">
              Enter the following required information to create your account.
            </p>
            <p
              className={`flex w-full items-center label-style font-semibold ${
                errors.username ? "errorColor" : "text-darkgrey"
              }`}
            >
              Username
            </p>
            <div
              className={`w-full h-11 rounded-md px-4 input-style ${
                errors.username && "errorHightlight"
              }`}
            >
              <input
                type="username"
                name="username"
                value={inputFields.username}
                onChange={handleChange}
                className="fos:outline-none my-2 w-full border-none bg-transparent outline-none"
              />
              {errors.username && (
                <div className="flex relative my-2 error userMargin">
                  <span
                    style={{ paddingTop: "3px" }}
                    className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                  >
                    info
                  </span>
                  <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                    {errors.username}
                  </p>
                </div>
              )}
            </div>
            <p className="flex w-full"></p>
            <ul className="mt-2 ml-3 w-full list-disc username-passowrd-tip">
              <li>Between 6 and 30 characters</li>
              <li>Can contain letters, numbers, and special characters</li>
            </ul>
            <p
              className={`flex w-full items-center label-style font-semibold mt-2 ${
                errors.password ? "errorColor" : "text-darkgrey"
              }`}
            >
              Password
            </p>
            <div
              className={`relative flex items-center w-full h-11 rounded-md px-4 border border-black ${
                errors.password && "errorHightlight"
              }`}
            >
              <input
                type={isPassword ? "password" : "text"}
                name="password"
                value={inputFields.password}
                onChange={handleChange}
                className="fos:outline-none my-2 w-full border-none bg-transparent outline-none"
              />
              <span
                className="material-icons-outlined text-primary icon-font-family"
                onClick={() => setPassword(!isPassword)}
              >
                visibility
              </span>
            </div>
            {errors.password && (
              <div className="flex relative my-2 error">
                <span
                  style={{ paddingTop: "3px" }}
                  className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                >
                  info
                </span>
                <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                  {errors.password}
                </p>
              </div>
            )}
            <p
              className={`flex w-full mt-4 items-center label-style font-semibold ${
                errors.confirm ? "errorColor" : "text-darkgrey"
              }`}
            >
              Confirm Password
            </p>
            <div
              className={`relative flex items-center w-full h-11 rounded-md px-4 border border-black ${
                errors.confirm && "errorHightlight"
              }`}
            >
              <input
                type={isConfirmPassword ? "password" : "text"}
                name="confirm"
                value={inputFields.confirm}
                onChange={handleChange}
                className="fos:outline-none my-2 w-full border-none bg-transparent outline-none"
              />
              <span
                className="material-icons-outlined text-primary icon-font-family"
                onClick={() => setConfirmPassword(!isConfirmPassword)}
              >
                visibility
              </span>
            </div>
            {errors.confirm && (
              <div className="flex relative my-2 error">
                <span
                  style={{ paddingTop: "3px" }}
                  className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                >
                  info
                </span>
                <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                  {errors.confirm}
                </p>
              </div>
            )}
            <p className="flex w-full"></p>
            <ul className="mt-2 ml-3 w-full list-disc username-passowrd-tip">
              <li>Between 6 and 30 characters</li>
              <li>Must include 1 letter and 1 number</li>
              <li>
                Cannot be the same as username or easily guessed, like
                "password"
              </li>
            </ul>
            <button
              className="w-full h-11 mt-10 rounded-md bg-primary hover:bg-primary active:translate-y-[0.125rem] active:border-b-primary text-white font-semibold text-3xl"
              type="submit"
            >
              Create
            </button>
            {showError && (
              <Popup
                ref={popupRef}
                popUpType={PopUpType.Message}
                headerText="Error"
                middleContent={showError}
                acceptButtonText="OK"
                acceptButtonEvent={() => {
                  setShowError("");
                }}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { LoadingProvider } from "./auth/loader/loading.context";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Validation } from "./module/validation";
import { PreferredCommunication } from "./module/preferred_communication";
import { CreateAccount } from "./module/create_account";
import { InviteVerification } from "./module/invite_verification";
import { getHealthSparkIcon } from "./assets/config/healthspark-icon-white-labelling";

var appName = "mozzaz";

const whichApp = () => {
  const currentURL = window.location.href;
  if (currentURL.includes("dulalytics")) {
    appName = "dula";
  } else if (currentURL.includes("corelife")) {
    appName = "corelife";
  } else if (currentURL.includes("healthspark")) {
    appName = "healthspark";
  } else appName = "mozzaz";
  return null;
};

function App() {
  return (
    <LoadingProvider>
      <div className="App">
        {whichApp()}
        {appName == "mozzaz" && (
          <Helmet>
            <title>Mozzaz User Onboarding</title>
            <link rel="manifest" href="/manifest.json" />
            <link rel="apple-touch-icon" sizes="180x180" href="/logo180.png" />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/logo16.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/logo32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="48x48"
              href="/logo48.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="128x128"
              href="/logo128.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/logo192.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="512x512"
              href="/logo512.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="1024x1024"
              href="/logo1024.png"
            />
          </Helmet>
        )}
        {appName == "dula" && (
          <Helmet>
            <title>Dulalytics</title>
            <link rel="manifest" href="/manifestD.json" />
            <link rel="apple-touch-icon" sizes="180x180" href="/logo180D.png" />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/logo16D.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/logo32D.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="48x48"
              href="/logo48D.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="128x128"
              href="/logo128D.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/logo192D.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="512x512"
              href="/logo512D.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="1024x1024"
              href="/logo1024D.png"
            />
          </Helmet>
        )}
        {appName == "corelife" && (
          <Helmet>
            <title>CoreLife</title>
            <link rel="manifest" href="/manifestCL.json" />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/logo180CL.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/logo16CL.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/logo32CL.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="48x48"
              href="/logo48CL.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="128x128"
              href="/logo128CL.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/logo192CL.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="512x512"
              href="/logo512CL.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="1024x1024"
              href="/logo1024CL.png"
            />
          </Helmet>
        )}
        {appName == "healthspark" && 
						getHealthSparkIcon()
        }
        <div className="content" id="contentRoot">
          <AppComponets />
        </div>
      </div>
    </LoadingProvider>
  );
}

function AppComponets() {
  return (
    <Routes>
      <Route path="/verify" element={<InviteVerification />} />
      <Route path="/validate" element={<Validation />} />
      <Route
        path="/preferredcommunication"
        element={<PreferredCommunication />}
      />
      <Route path="/createaccount" element={<CreateAccount />} />
    </Routes>
  );
}

export default App;

import { Helmet } from "react-helmet";

export const getHealthSparkIcon = () => {
  return (
    <Helmet>
      <title>HealthSpark </title>
      <link rel="manifest" href="/manifestHS.json" />
      <link
        rel="shortcut icon"
        id="shortcuticon"
        type="image/x-icon"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/favicon.ico"
      />
      <link rel="icon" id="favicon" type="image/x-icon" href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/favicon.ico" />
      <link
        id="icon57"
        rel="apple-touch-icon"
        type="image/png"
        sizes="57x57"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-57x57.png"
      />
      <link
        id="icon114"
        rel="apple-touch-icon"
        type="image/png"
        sizes="114x114"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-114x114.png"
      />
      <link
        id="icon72"
        rel="apple-touch-icon"
        sizes="72x72"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-72x72.png"
      />
      <link
        id="icon144"
        rel="apple-touch-icon"
        sizes="144x144"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-144x144"
      />
      <link
        id="icon60"
        rel="apple-touch-icon"
        sizes="60x60"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-60x60.png"
      />
      <link
        id="icon120"
        rel="apple-touch-icon"
        sizes="120x120"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-120x120.png"
      />
      <link
        id="icon76"
        rel="apple-touch-icon"
        sizes="76x76"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-76x76.png"
      />
      <link
        id="icon152"
        rel="apple-touch-icon"
        sizes="152x152"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-152x152.png"
      />
      <link
        id="icon196"
        rel="icon"
        type="image/png"
        sizes="196x196"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/favicon-196x196.png"
      />
      <link
        id="icon180"
        rel="apple-touch-icon"
        sizes="180x180"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-180x180.png"
      />
      <link
        id="icon167"
        rel="apple-touch-icon"
        sizes="167x167"
        type="image/png"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/apple-icon-167x167.png"
      />
      <link
        id="icon96"
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/favicon-96x96.png"
      />
      <link
        id="icon32"
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/favicon-32x32.png"
      />
      <link
        id="icon16"
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/favicon-16x16.png"
      />
      <link
        id="icon128"
        rel="icon"
        type="image/png"
        sizes="128x128"
        href="https://mzmedia.blob.core.windows.net/app-icons/healthspark/favicon-128x128.png"
      />
      <meta name="application-name" content="https://mzmedia.blob.core.windows.net/app-icons/healthspark/&nbsp;" />
      <meta name="msapplication-TileColor" content="https://mzmedia.blob.core.windows.net/app-icons/healthspark/#00FFFFFF" />
      <meta
        id="iconms144"
        name="msapplication-TileImage"
        content="https://mzmedia.blob.core.windows.net/app-icons/healthspark/mstile-144x144.png"
      />
      <meta
        id="iconms70"
        name="msapplication-square70x70logo"
        content="https://mzmedia.blob.core.windows.net/app-icons/healthspark/mstile-70x70.png"
      />
      <meta
        id="iconms150"
        name="msapplication-square150x150logo"
        content="https://mzmedia.blob.core.windows.net/app-icons/healthspark/mstile-150x150.png"
      />
      <meta
        id="iconmsW150"
        name="msapplication-wide310x150logo"
        content="https://mzmedia.blob.core.windows.net/app-icons/healthspark/mstile-310x150.png"
      />
      <meta
        id="iconms310"
        name="msapplication-square310x310logo"
        content="https://mzmedia.blob.core.windows.net/app-icons/healthspark/mstile-310x310.png"
      />
    </Helmet>
  );
};
